<template>
  <div class="text-dark-blue login">
    <router-link to="/" class="text-dark-blue">
      <h1 class="mb-5 text-dark-blue">{{ $t("documentManagementSystem") }}</h1>
    </router-link>
    <div class="login-form">
      <Alert :alert="alert" />
      <b-form class="form-style" @submit.prevent="sendPassword">
        <b-form-group class="password-icon">
          <label for="newPassword">{{ $t("newPassword") }}</label>
          <b-form-input
            :type="!passEye ? 'password' : 'text'"
            id="newPassword"
            v-model="$v.form.newPassword.$model"
            :state="!$v.form.newPassword.$error"
          />
          <span @click="passEye = !passEye">
            <font-awesome-icon
              :icon="passEye ? faEye : faEyeSlash"
              class="input-icon"
            />
          </span>
          <b-form-invalid-feedback v-if="!$v.form.newPassword.required">{{
            $t("validateMessages.newPassword")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="password-icon">
          <label for="againPassword">{{ $t("againNewPassword") }}</label>
          <b-form-input
            :type="!passAgainEye ? 'password' : 'text'"
            id="againPassword"
            v-model="$v.form.newAgainPassword.$model"
            :state="!$v.form.newAgainPassword.$error"
          />
          <span @click="passAgainEye = !passAgainEye">
            <font-awesome-icon
              :icon="passAgainEye ? faEye : faEyeSlash"
              class="input-icon"
            />
          </span>
          <b-form-invalid-feedback v-if="!$v.form.newAgainPassword.required">{{
            $t("validateMessages.againNewPassword")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.newAgainPassword.sameAs">{{
            $t("validateMessages.newPasswordValidate")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <button class="blue-button w-100 mt-3">
          <span v-if="!loading">{{ $t("send") }}</span>
          <b-spinner variant="light" v-else></b-spinner>
        </button>
      </b-form>
    </div>
  </div>
</template>

<script>
import Input from "../base/formElements/Input.vue";
import { validationMixin } from "vuelidate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Alert from "../base/alert/Alert.vue";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { sendNewPassword } from "../../services/modules/User";

const { required, sameAs } = require("vuelidate/lib/validators");

export default {
  components: {
    Input,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      form: {
        newPassword: "",
        newAgainPassword: "",
      },
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      loading: false,
      faEye,
      faEyeSlash,
      passEye: false,
      passAgainEye: false,
    };
  },
  methods: {
    async sendPassword() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const response = await sendNewPassword(
          this.form.newPassword,
          this.$route.query.token
        );

        if (response.message === "OK") {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "renewedPassword";
        } else {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "notRenewedPassword";
        }

        this.loading = false;
        setTimeout(() => {
          this.alert.show = false;
          this.$router.push("/anmeldung");
        }, 3000);
      }
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      newPassword: {
        required,
      },
      newAgainPassword: {
        required,
        sameAs: sameAs("newPassword"),
      },
    },
  },
};
</script>

<style lang="scss">
.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/image-3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .login-form {
    width: 100%;
    max-width: 500px !important;
    padding: 0 20px;
  }

  .forgot-password-route {
    p {
      text-decoration: underline;
    }
  }

  .password-icon {
    position: relative;

    .input-icon {
      position: absolute;
      top: 60%;
      right: 1.2rem;
      color: #01295f;
    }
  }
}
</style>