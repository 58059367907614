<template>
  <NewPassword />
</template>

<script>
import NewPassword from "../components/forgotPassword/NewPassword.vue";

export default {
  components: {
    NewPassword,
  },
};
</script>

<style>
</style>